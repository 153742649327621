export const teamMembers = [
    { name: 'Kyle Rolfing', image: '/headshots/Kyle-BW-450.jpg', title: 'Chief Executive Officer', bio: 'Kyle is a serial healthcare entrepreneur with particular experience in next-generation tools for employers to manage health benefits. Kyle was a founder at Definity Health (acquired by United Health for $300m in 2004), Redbrick Health (acquired by Marlin Equity Partners in 2018), and Bright Health (IPO 2021, NYSE:BHG).'},
    { name: 'Gerardo Zampaglione', image: '/headshots/Gerardo-BW-450.jpg', title: 'Founder & Chief Product Officer', bio: 'Gerardo was a Population Health lead for Epic Systems, the EMR vendor, where he advised health systems and provider-owned health plans on innovative methods for handling downside risk following the passing of the Affordable Care Act. Prior to joining Aegle, Gerardo was a Principal at Kaiser Associates and Leonessa Life Sciences.' },
    { name: 'Drew Holm', image: '/headshots/Drew-BW-450.jpg', title: 'Chief Operating Officer', bio: 'Drew brings almost 20 years of experience in healthcare where he has led finance, mergers and acquisitions, strategic partnerships, strategy, HR, and corporate development. Drew was most recently the CFO of Recuro Health and additionally held leadership roles at Constellation, Inc., Stella, the parent company of Blue Cross Blue Shield of Minnesota, and United Health Group.' },
    { name: 'Barry Carlson', image: '/headshots/Barry-BW-450.jpg', title: 'Chief Growth Officer', bio: 'Barry brings a wealth of experience in the insurance, healthcare, and employee benefits domains, with a career spanning more than 30 years. His background is rooted in sales leadership and business development. Barry has had the privilege of working with respected companies including Aon, Nexben, Allina Health/Aetna, Medica and ING.' },
    { name: 'Jenna Christensen', image: '/headshots/Jenna-BW-450.jpg', title: 'VP, Strategic Operations', bio: 'Jenna brings over 14 years of experience in healthcare where she has led finance, operations and business integration efforts. Prior to joining Aegle, Jenna was Vice President of Operations Enablement at Optum Financial.' },
    { name: 'Jarrod Stenberg', image: '/headshots/Jarrod-BW-450.jpg', title: 'Principal Architect, Technology', bio: 'Jarrod brings over 20 years of technology and security experience to his role. He has a proven track record in enhancing organizational scalability and security, with previous positions at Entrust Datacard, UnitedHealth Group, and Wolters Kluwer. At Aegle, Jarrod’s expertise is instrumental in developing robust technology architectures that support and drive the company’s growth.' },
    { name: 'Charlie Trepanier', image: '/headshots/Charlie-BW-450.jpg', title: 'VP, Finance and Controller', bio: '' },
    { name: 'Tye Rambo', image: '/headshots/Tye-BW-450.jpg', title: 'Client Relationship Manager', bio: '' },
    { name: 'Carl Schwappach', image: '/headshots/Carl-BW-450.jpg', title: 'Sales Enablement Manager', bio: '' },
    { name: 'Callie Estreicher', image: '/headshots/Callie-BW-450.jpg', title: 'Director of Strategic Operations', bio: '' },
    { name: 'Erika Townley', image: '/headshots/Erika-BW-450.jpg', title: 'Executive Assistant & Operations Manager', bio: '' },
    { name: 'Blake Thompson', image: '/headshots/Blake-BW-450.jpg', title: 'Operations Manager', bio: '' },
    { name: 'Dillon Baxendell', image: '/headshots/Dillon-BW-450.jpg', title: 'UX Engineer', bio: '' },
    // { name: 'Marc Seaberg', image: '/headshots/Marc-BW-450.jpg', title: 'UX Engineer', bio: '' },
    // { name: 'Tyler Wahlberg', image: '/headshots/Jarrod-BW-450.jpg', title: 'Financial & Operational Analyst', bio: '' },
]