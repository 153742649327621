import React from 'react';

interface AegleDividerProps {
    color?: string;
    width?: string;
    height?: string;
  }
  
  const AegleDivider: React.FC<AegleDividerProps> = ({
    color = '#192E58',
    width = '100px',
    height = '3px',
  }) => {
    return (
      <div
        style={{
          width,
          height,
          backgroundColor: color,
          margin: '1rem auto',
        }}
      ></div>
    );
  };
  

export default AegleDivider;
