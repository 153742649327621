import React, { useState } from 'react';
import './RiskFinancingPage.css';
import AegleDivider from '../components/AegleDivider';

const RiskFinancingPage: React.FC = () => {

    return (
        <div className="RiskFinancingPage">
            <section className="risk-financing-title-section">
                <h1>Risk Financing</h1>
                <AegleDivider color="#368caa" />
                <p>
                    Coming Soon!
                </p>
            </section>
        </div>
    );
};

export default RiskFinancingPage;