import React from 'react';
import './Footer.css';
import Button from '@mui/material/Button';
import { Divider, IconButton } from '@mui/material';
import AegleDivider from './AegleDivider';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer: React.FC = () => {
    return (
        <div className="Footer">
            {/* Footer Section */}
            <footer className="footer-section">
                <h2>ADAPTIVE CAPITAL <i>for</i> HEALTHCARE</h2>
                <a href="https://www.linkedin.com/company/aegle-cap/" target="_blank" rel="noopener noreferrer">
                    <IconButton 
                        size="large" 
                        sx={{
                            color: 'white', 
                            fontSize: '3rem', 
                            width: '80px', 
                            height: '80px',
                        }}
                    >
                        <LinkedInIcon fontSize="inherit" />
                    </IconButton>
                </a>
                <img src="/LogoReverse.svg" alt="Logo" className="footer-logo" />
                <p>&copy; 2024 Aegle Capital, Inc. All Rights Reserved.</p>
            </footer>
        </div>
    );
};

export default Footer;
