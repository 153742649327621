import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import Footer from './components/Footer';
import AccelPage from './pages/AccelPage';
import RBCSupportPage from './pages/RBCSupportPage';
import RiskFinancingPage from './pages/RiskFinancingPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/accel" element={<AccelPage />} />
            <Route path="/riskfinancing" element={<RiskFinancingPage/>} />
            <Route path="/rbc" element={<RBCSupportPage/>} />
          </Routes>
          <Footer/>
        </main>
      </div>
    </Router>
  );
}

export default App;
