import React from 'react';
import './AccelPage.css';
import { Button, Link } from '@mui/material';
import AegleDivider from '../components/AegleDivider';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SecurityIcon from '@mui/icons-material/Security';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import GavelIcon from '@mui/icons-material/Gavel';
import VerifiedIcon from '@mui/icons-material/Verified';
import SavingsIcon from '@mui/icons-material/Savings';
import WorkIcon from '@mui/icons-material/Work';

const AccelPage: React.FC = () => {
    return (
        <div>
            {/* Header Section */}
            <section className="header-section">
                <h1>Aegle Accel</h1>
                <AegleDivider color="#368caa"/>
                <p className="tagline">Putting the Stop-Loss Market to Work</p>
                <p>
                    Aegle Accel enhances independent stop-loss insurance by providing expedited reimbursements, significantly reducing the wait times typically associated with claims. This advancement allows employers to leverage the competitive rates of the independent stop-loss market, driving down overall stop-loss costs and enabling savings.
                </p>
                {/* <Link className="case-study-button" underline='always' sx={{color: 'white'}}>
                    See How One 860-Employee Company Saved $150,000 Annually
                </Link> */}
            </section>

{/* Key Features Section */}
<section className="key-features-section">
  <h2>Key Features</h2>
  <AegleDivider color="#368caa"/>
  <div className="key-features-grid">
    {/* Feature 1 */}
    <div className="key-feature-item">
      <AssignmentTurnedInIcon className="feature-icon" sx={{fontSize: '3rem'}}/>
      <div>
        <h3>Fast Funding</h3>
        <p>
          Claims are funded on an expedited basis, ensuring quick cash flow support. Exceptions may apply for claims exceeding $5 million.
        </p>
      </div>
    </div>

    {/* Feature 2 */}
    <div className="key-feature-item">
      <SecurityIcon className="feature-icon" sx={{fontSize: '3rem'}} />
      <div>
        <h3>Plan Protection</h3>
        <p>
          Aegle funds claims upfront, protecting your health plan from the financial delays associated with reimbursement processing.
        </p>
      </div>
    </div>

        {/* Feature 3 */}
        <div className="key-feature-item">
      <AttachMoneyIcon className="feature-icon" sx={{fontSize: '3rem'}} />
      <div>
        <h3>Straight-Forward Pricing</h3>
        <p>
            A simple Per Employee Per Month (PEPM) fee with no unexpected variable costs.
        </p>
      </div>
    </div>

        {/* Feature 2 */}
        <div className="key-feature-item">
      <SyncAltIcon className="feature-icon" sx={{fontSize: '3rem'}} />
      <div>
        <h3>Simplified Process</h3>
        <p>
           Minimal paperwork for clients. Aegle dispatches funds quickly and collects reimbursement directly from the carrier.
        </p>
      </div>
    </div>
  </div>
</section>

{/* Plan Protection Details */}
<section className="plan-protection-section">
  <h2>Protect Your Plan</h2>
  <AegleDivider color="#368caa" />
  <p className="protection-intro">
    Plan Protection shields your health plan from disruptions in the reimbursement process, ensuring cash flow continuity and accurate claims processing. Aegle handles advanced funding and manages reimbursement recovery directly with the carrier.
  </p>
  <div className="protection-grid">
    <div className="protection-item">
      <GavelIcon className="protection-icon" />
      <div>
        <h3>Administrative Errors</h3>
        <p>Aegle provides funding upfront, alleviating immediate financial stress during recovery.</p>
      </div>
    </div>
    <div className="protection-item">
      <VerifiedIcon className="protection-icon" />
      <div>
        <h3>Authorization Issues</h3>
        <p>Aegle advances funds, ensuring finances remain stable while TPAs recover from providers.</p>
      </div>
    </div>
    <div className="protection-item">
      <SavingsIcon className="protection-icon" />
      <div>
        <h3>Negotiated Adjustments</h3>
        <p>Adjustments are reimbursed from savings, with fees addressed by the stop-loss carrier.</p>
      </div>
    </div>
    <div className="protection-item">
      <WorkIcon className="protection-icon" />
      <div>
        <h3>Worker’s Compensation Claims</h3>
        <p>Funding keeps cash flow intact during lengthy recovery processes.</p>
      </div>
    </div>
  </div>
</section>

        </div>
    );
};

export default AccelPage;