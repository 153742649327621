import React, { useState } from 'react';
import './RBCSupportPage.css';
import AegleDivider from '../components/AegleDivider';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider } from '@mui/material';
import { teamMembers } from './teamMembers';

const RBCSupportPage: React.FC = () => {

    return (
        <div className="RBCSupportPage">
            <section className="rbc-title-section">
                <h1>RBC Support</h1>
                <AegleDivider color="#368caa" />
                <p>
                    Coming Soon!
                </p>
            </section>
        </div>
    );
};

export default RBCSupportPage;
