import React, { useState } from 'react';
import './AboutPage.css';
import AegleDivider from '../components/AegleDivider';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider } from '@mui/material';
import { teamMembers } from './teamMembers';

const AboutPage: React.FC = () => {

    const [open, setOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState<null | typeof teamMembers[0]>(null);

    const handleClickOpen = (member: typeof teamMembers[0]) => {
        setSelectedMember(member);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedMember(null);
    };

    return (
        <div className="AboutPage">
            {/* About Us Section */}
            <section className="about-us-section">
                <h1>About Us</h1>
                <AegleDivider color="#368caa" />
                <p>
                    At Aegle, we develop cutting-edge solutions that fundamentally transform the way self-insured and risk-bearing entities manage their healthcare funding risks. Our risk financing solutions are crafted to enhance traditional stop-loss and reinsurance methods, providing a cost-effective and capital-efficient alternative.
                </p>
            </section>

            {/* Our Team Section */}
            <section className="our-team-section">
                <h2>Our Team</h2>
                <AegleDivider color="#368caa" />
                <p>
                    Our team at Aegle Capital is composed of experts from the healthcare, structured finance, reinsurance, alternative risk, and technology sectors who are committed to developing innovative financial solutions in healthcare funding. With a forward-thinking approach to strategy and innovation, we are actively shaping the future of healthcare finance.
                </p>
                <div className="team-grid">
                    {teamMembers.map((member, index) => (
                        <div
                            className="team-member"
                            key={index}
                            onClick={() => handleClickOpen(member)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div
                                className="image-wrapper"
                                style={{
                                    backgroundColor: '#192e58',
                                    transition: '0.3s',
                                    position: 'relative',
                                }}
                            >
                                <img src={member.image} alt={member.name} />
                            </div>
                            <h2 className="team-name">{member.name}</h2>
                            <p className="team-title">{member.title}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Dialog for Member Information */}
            {selectedMember && (
                <Dialog open={open} onClose={handleClose} aria-labelledby="team-member-dialog" maxWidth="md" fullWidth>
                <DialogContent>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem', marginTop: '2rem' }}>
                        {/* Image on the left */}
                        <img
                            className=""
                            src={selectedMember?.image}
                            alt={selectedMember?.name}
                            style={{
                                width: '250px',
                                height: '250px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                                border: '3px solid #192e58'
                            }}
                        />
                        {/* Text on the right */}
                        <div>
                            <h2
                                style={{
                                    margin: 0,
                                    fontWeight: 'bold',
                                    color: '#192e58',
                                    fontSize: '1.5rem',
                                }}
                            >
                                {selectedMember?.name}
                            </h2>
                            <p
                                style={{
                                    marginTop: '0.25rem',
                                    fontSize: '1.1rem',
                                    fontStyle: 'italic',
                                    color: '#368CAA',
                                }}
                            >
                                {selectedMember?.title}
                            </p>
                            <p style={{ marginTop: '1rem', lineHeight: '1.75', fontSize: '1rem' }}>
                                {selectedMember?.bio}
                            </p>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>            
            )}
        </div>
    );
};

export default AboutPage;
