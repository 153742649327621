import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar: React.FC = () => {
  const location = useLocation(); // Get the current path

  // Determine the active link based on the current path
  const getActiveLink = (path: string) => {
    switch (path) {
      case '/':
        return 'Home';
      case '/about':
        return 'About Us';
      case '/accel':
        return 'Accel';
      case '/riskfinancing':
        return 'Risk Financing';
      case '/rbc':
        return 'RBC Support';
      default:
        return '';
    }
  };

  const activeLink = getActiveLink(location.pathname);

  return (
    <nav className="Navbar">
      <div className="Navbar-logo">
        <Link to="/">
          <img src="/LogoWhite.png" alt="Logo" className="Navbar-logo-img" />
        </Link>
      </div>
      <ul className="Navbar-links">
        <li>
          <Link
            to="/"
            className={activeLink === 'Home' ? 'active' : ''}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/accel"
            className={activeLink === 'Accel' ? 'active' : ''}
          >
            Accel
          </Link>
        </li>
        <li>
          <Link
            to="/riskfinancing"
            className={activeLink === 'Risk Financing' ? 'active' : ''}
          >
            Risk Financing
          </Link>
        </li>
        <li>
          <Link
            to="/rbc"
            className={activeLink === 'RBC Support' ? 'active' : ''}
          >
            RBC Support
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={activeLink === 'About Us' ? 'active' : ''}
          >
            Company
          </Link>
        </li>
        {/* Add more links here */}
      </ul>
    </nav>
  );
};

export default Navbar;