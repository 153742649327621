import React from 'react';
import './HomePage.css';
import Button from '@mui/material/Button';
import { Divider, IconButton } from '@mui/material';
import AegleDivider from '../components/AegleDivider';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useNavigate } from 'react-router-dom';

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="HomePage">
            {/* Title Section */}
            <section className="HomePage-section title-section">
                <img src="/LogoReverse.svg" alt="Logo" className="title-logo" />
                <h1>ADAPTIVE CAPITAL <i>for</i> HEALTHCARE</h1>
                <AegleDivider color="#368CAA" />
                <p>
                    Aegle is the first company that provides adaptive capital solutions to address fundamental challenges in employer-sponsored healthcare.
                </p>
            </section>

            {/* What We Do Section */}
            <section className="HomePage-section what-we-do-section">
                <h2>Redefining How Employers Fund Health Benefits</h2>
                <AegleDivider color="#368caa" />
                <p className="subtitle">
                    We provide custom-built financial solutions to address common challenges faced by self-insured employers in funding employee health benefits.
                </p>
                <div className="what-we-do-content">
                    {/* Section 1 */}
                    <div className="what-we-do-item">
                        <div className="circle-title">
                            <div className="circle">1</div>
                            <h3>Accelerating Funding & Reimbursement</h3>
                        </div>
                        <p>
                            Aegle Accel makes lengthy stop-loss reimbursement nearly instantaneous, and helps TPAs meet rental network requirements around prompt payment.
                        </p>
                        <Button variant="contained" 
                                color="primary" 
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate('/accel')
                                }}>
                                    Learn More
                        </Button>
                    </div>
                    <Divider orientation="vertical" flexItem />

                    {/* Section 2 */}
                    <div className="what-we-do-item">
                        <div className="circle-title">
                            <div className="circle">2</div>
                            <h3>Gaps in Coverage</h3>
                        </div>
                        <p>
                            Aegle Risk Financing helps employers to effectively manage claim and cashflow volatility.
                        </p>
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => {
                                window.scrollTo(0, 0);
                                navigate('/riskfinancing')
                            }}>
                                Learn More
                        </Button>
                    </div>
                    <Divider orientation="vertical" flexItem />

                    {/* Section 3 */}
                    <div className="what-we-do-item">
                        <div className="circle-title">
                            <div className="circle">3</div>
                            <h3>Improving Financial Efficiency</h3>
                        </div>
                        <p>
                            Level by Aegle enables health plans and TPAs to offer a next-generation level-funding solution that optimizes employer capital.
                        </p>
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => {
                                window.scrollTo(0, 0);
                                navigate('/rbc')
                            }}>
                                Learn More
                        </Button>
                    </div>
                </div>
            </section>

            {/* Our Mission Section */}
            <section className="HomePage-section mission-section">
                <h2>Our Mission</h2>
                <AegleDivider color="#368caa" />
                <p>
                    Aegle aims to help companies manage their employee health benefits in a more capital-efficient manner.
                </p>
            </section>
        </div>
    );
};

export default HomePage;
